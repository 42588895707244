import React, { Component } from "react";
import { baseUrl } from "../const_var";
import axios from "axios";

import {
  ref,
  get,
  set,
  push,
  query,
  equalTo,
  orderByChild,
} from "firebase/database";
import { firestore, database } from "../firebaseConfig";
import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";

export default class AddShipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      dateDropped: "",
      parcelWeight: 0,
      success: false,
      error: false,
      provider:"",
      useProviderTrackingID: false,
      loading:false,
      // today:Date.now().toString(),
    };

    // this.handleAddShipping = this.handleAddShipping.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleReset() {
    this.setState({
      // error: false,
      // success: false,

      customerName: "",
      dateDropped: "",
      parcelWeight: "",
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: name == "useProviderTrackingID" ? !this.state.useProviderTrackingID: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let {customerName,dateDropped,parcelWeight,useProviderTrackingID,providerTrackingID} = this.state

    this.setState({loading:true})
    let data = { customerName:customerName.toLowerCase(), dateDropped, parcelWeight}

    // if(customerName == "" ||dateDropped=="" || parcelWeight==0){
    //   return 
    // }

    
    const mdateObj = new Date(Date.now());
    
    const year = mdateObj.getFullYear();
    const month = mdateObj.getMonth();
    const date = mdateObj.getDate();
    const hr = mdateObj.getHours();
    const mins = mdateObj.getMinutes();
    const secs = mdateObj.getSeconds();

    const ymDateString = `${year}-${month}-${date} 00:00:00`;
    data.createdAt = `${year}-${month}-${date} ${hr}:${mins}:${secs}`;
   
    const mpdateObj = new Date(ymDateString).getTime();


    

    const pathRef = ref(
      database,
      "uwave/" + mpdateObj
    );

    // const { customerName, dateDropped, parcelWeight } = this.state;

    let value = {};

      // Check if the path exists
      if(!useProviderTrackingID){
        this.state.providerTrackingID =undefined
      }
     let requestData = this.state
      requestData.provider = this.state.trackingProvider
      requestData.provider = this.state.trackingProvider
      const token = localStorage.getItem("userToken")
      console.log("called,this.state",this.state)
      console.log("called,requestData",requestData)
            axios.post(`${baseUrl}/freight-receipts/admin`,
              requestData
            ,{
              headers:{
                "Authorization":`Bearer ${token}`,
                'Content-Type':'application/json',
              },
              responseType: 'blob'

            }).then(response =>{
              this.setState({loading:false})
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
              // window.open(url);
              window.open(url, '_blank', 'noopener,noreferrer'); // Opens in a new tab

            }).catch(e => {
              console.log("ERROR::",e)
              this.setState({error:e.response.data.message                })
            })
    // finally {
    //   window.document.getElementById("showMessageModal")?.showModal();
    //   this.handleReset();
    // }
   
  };

  componentDidMount() {
    // const password = window.prompt("enter password")
    // if(password !== "08182090541"){
    //   window.location.href= "/"
    //   // return
    // }
  }

  //  SaveTextRealtime = () =>{
  //   const [text, setText] = useState("");

  //   const handleSubmit = (e) => {
  //     e.preventDefault();

  //     // Save text to Realtime Database
  //     set(ref(database, 'texts/' + Date.now()), {
  //       text: text,
  //     })
  //     .then(() => {
  //       console.log("Text saved successfully!");
  //       setText(""); // Clear input after saving
  //     })
  //     .catch((error) => {
  //       console.error("Error saving text: ", error);
  //     });
  //   };

  
  // firestore
  //   handleAddShipping = async() => {

  //     // axios.post(`${baseUrl}/logistics/exists`,{
  //     //   ...this.state
  //     // }).then(res => {
  //     //   console.log("res",res)
  //     // })
  //     const now =new Date()
  //    const today = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
  //     this.state.createdAt = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;

  //     console.log("Text saved successfully!1",this.state);

  //     // console.log("Text saved successfully!1",database);
  // // return

  //   //   set(ref(database, 'uwave/shipping/' + today), {
  //   //     ...this.state
  //   // }).then(() => {
  //   //   console.log("Text saved successfully!");
  //   // })
  //   // .catch((error) => {
  //   //   console.error("Error saving text: ", error);
  //   // });
  //   try {
  //     const docRef = await addDoc(collection(firestore, 'uwave/' + today+"/"+this.state.customerName), this.state);

  //    window.confirm("Shippment saved successfully")
  //   } catch (e) {
  //     window.confirm("Error adding document: ", e)
  //   }

  //   }

  


  closeModal() {
    // Close the modal and reset the state
    this.setState({
      error: false,
      success: false,
      message: '',
    });

    // Close the modal programmatically
    document.getElementById('showMessageModal')?.close();
  }
  render() {
    const {useProviderTrackingID} = this.state
    return (
      <div className="container  h-screen p-4 flex flex-col items-center justify-center">
        <div className="  bg-blue pb-4 rounded ">
          <h1 className=" font-bold ">Enter new shipment details.</h1>
        </div>

        <dialog
          id="showMessageModal"
          className={`bg-white w-5/12 border-2 border-black-600 rounded text-center ${
            this.state.error
              ? "text-red-600"
              : this.state.success
              ? "text-green-600"
              : "text-black-400"
          }`}
        >
          {(this.state.error || this.state.success) && (
            <form
              method="dialog"
              className="modal-header p-4 flex justify-between items-center"
            >
              <button type="button" onClick={this.closeModal}>
                <span className="text-2xl" aria-hidden="true">
                  &times;
                </span>
              </button>
              <div>{this.state.message}</div>
            </form>
          )}
        </dialog>
        <div className=" p-4 bg-gray-100 w-7/12 rounded  ">
          <div className="p-2  w-full">
            <label
              className=" block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              Customer Name
            </label>
            <input
              className="w-full bg-inputLight flex p-3 rounded-xl  border border-gray-300 outline-none focus:outline-none"
              placeholder="Enter Customer Name"
              name="customerName"
              onChange={this.handleChange}
              required={true}
            />
          </div>
          <div className="p-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              Date Dropped
            </label>
            <input
              className="w-full bg-inputLight flex p-3 rounded-xl  border border-gray-300 outline-none focus:outline-none"
              name="dateDropped"
              type="date"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="p-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              Parcel Weight
            </label>
            <input
              className="w-full bg-inputLight flex p-3 rounded-xl  border border-gray-300 outline-none focus:outline-none"
              name="parcelWeight"
              placeholder="Enter weight in kg"
              type="number"
              onChange={this.handleChange}
              required
            />
          </div>
          
          <div className="p-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              No of Package
            </label>
            <input
              className="w-full bg-inputLight flex p-3 rounded-xl  border border-gray-300 outline-none focus:outline-none"
              name="parcelWeight"
              placeholder="Enter weight in kg"
              type="number"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="p-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              Origin
            </label>
            <input
              className="w-full bg-inputLight flex p-3 rounded-xl  border border-gray-300 outline-none focus:outline-none"
              name="parcelWeight"
              placeholder="Enter weight in kg"
              type="number"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="p-2">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="username"
            >
              ETA
            </label>
            <input
              className="w-full bg-inputLight flex p-3 rounded-xl  border border-gray-300 outline-none focus:outline-none"
              name="parcelWeight"
              placeholder="Enter weight in kg"
              type="number"
              onChange={this.handleChange}
              required
            />
          </div>

          <div className=" items-center">
            <div className="flex item-center my-2 p-2">
              <label
                className="block text-gray-700 text-sm font-bold mr-2 "
                for="username"
              >
                Add Provider TrackingID ?
              </label>
              <input
              className="accent-green-300"
                onChange={this.handleChange}
                type="checkbox"
                name="useProviderTrackingID"
                value={useProviderTrackingID}
              />
            </div>
            {useProviderTrackingID&&
            <>
              <div className="p-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Tracking Provider 
                </label>
                <input
                  className="w-full bg-inputLight flex p-3 rounded-xl  border border-gray-300 outline-none focus:outline-none"
                  name="trackingProvider"
                  placeholder="Enter Tracking Provider"
                  type="text"
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="p-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  for="username"
                >
                  Provider TrackingID
                </label>
                <input
                  className="w-full bg-inputLight flex p-3 rounded-xl  border border-gray-300 outline-none focus:outline-none"
                  name="providerTrackingID"
                  placeholder="Enter provider TrackingID"
                  type="text"
                  onChange={this.handleChange}
                  required
                />
              </div>

            </>
            
            }
          </div>
          <div className="text-center">
            <button
              disabled={this.state.error || this.state.success || this.state.loading}
              onClick={this.handleSubmit}
              className={`  btn rounded p-2 text-white font-bold  ${
                this.state.error || this.state.success || this.state.loading
                  ? "bg-gray-400"
                  : "bg-red-500"
              } `}
            >
             {this.state.loading ? "Please wait ..." : "Track Now"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
