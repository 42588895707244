import React, { Component } from 'react'

import { getDatabase, ref, child, get, onValue } from "firebase/database";
import { firestore, database } from "../firebaseConfig";

import { baseUrl } from '../const_var';
import axios from "axios";
export default class ShippmentList extends Component {
    constructor(props){
        super(props)
        this.state={
            customerDataList:[],
            freights:[],
            customerDate:[{

            }]
        }

        this.getList = this.getList.bind(this);
        this.fetchData = this.fetchData.bind(this);

    }

    componentDidMount(){
      const role = localStorage.getItem("role");
      if (role != "admin"){
        this.getFreights()
      }else if(role == "admin"){
        this.getAdminFreights()
      }
    }

    async fetchData(item){
        const pathRef = ref(
            database,
            "uwave/" + item
          );
      
        const snapshot = await get(pathRef);

        if (snapshot.exists()) {
            let list =[]
            snapshot.forEach((childSnapshot) => {

                list.push(childSnapshot.val())
                console.log(" FOUND",childSnapshot.val())
                
            })
            this.setState({customerDataList:list})
        
        }else{
            console.log("NOT FOUND")
        }
    }

    fetchData2(item){
        const db = getDatabase()
        // return console.log("this.state.date",item);
        // return console.log(this.state.date);

        const starCountRef = ref(db, `uwave/ ${this.state.date}`);
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val(); // Get the data from the snapshot
            console.log("data:: ",data)
      
            if (data) {
                
                // const customerDate = Object.keys(data);
                // this.setState({
                //     customerDate,
                // })
           
              
            //   // Convert the data into an array
              const customerDataList = Object.values(data); // Convert the data into an array
              
              this.setState({
                customerDataList, // Store the list in the state
                error: false,
                errorMessage: '',
              });
            } else {
              // If there's no data, set an error
              this.setState({
                error: true,
                errorMessage: 'No customer data found.',
              });
            }
          }, (errorObject) => {
            // Handle the error from Firebase
            this.setState({
              error: true,
              errorMessage: `Error fetching data: ${errorObject.message}`,
            });
          });
    }

    getFreights(){
      const token = localStorage.getItem("userToken")
console.log("called")
      axios.get(`${baseUrl}/freights`,{
        headers:{
            "Authorization":`Bearer ${token}`
        }
      }).then(response =>{
         console.log("response::",response.data)
         console.log(" response.data.data.access_data::", response.data.access_data)
        this.setState({freights:response.data})
        
      
 
       }).catch(e => {
         
         console.log("ERROR::: ",e)
         console.log("ERROR::",e)
         this.setState({error:e.response.data.message
   
          })
       }) 

    }

    getAdminFreights(){
      const token = localStorage.getItem("userToken")
      console.log("token: ",token)
      axios.get(`${baseUrl}/admin/shipments`,{
        headers:{
            "Authorization":`Bearer ${token}`
        }
      }).then(response =>{
        this.setState({freights:response.data})
       }).catch(e => {
         
         console.log("ERROR::: ",e)
         console.log("ERROR::",e)
         this.setState({error:e.response.data.message
   
          })
       }) 

    }
    
     formatDate = (mongoDate) => {
      const date = new Date(mongoDate);
      

      const getDayWithSuffix = (day) => {
        if (day > 3 && day < 21) return day + "th";
        switch (day % 10) {
          case 1: return day + "st";
          case 2: return day + "nd";
          case 3: return day + "rd";
          default: return day + "th";
        }
      };

      const day = getDayWithSuffix(date.getDate());
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12 || 12; // Convert to 12-hour format
      
      return `${day} ${month} ${year} ${hours}:${minutes}${ampm}`;
    
    };

    getList(){
        const db = getDatabase()

        const starCountRef = ref(db, 'uwave/');
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val(); // Get the data from the snapshot
      
            if (data) {
                
                const customerDate = Object.keys(data);
                this.setState({
                    customerDate,
                })
           
            //     console.log("customerDate:: ",customerDate)
              
            //   // Convert the data into an array
            //   const customerDataList = Object.values(data); // Convert the data into an array
            //   this.setState({
            //     customerDataList, // Store the list in the state
            //     error: false,
            //     errorMessage: '',
            //   });
            } else {
              // If there's no data, set an error
              this.setState({
                error: true,
                errorMessage: 'No customer data found.',
              });
            }
          }, (errorObject) => {
            // Handle the error from Firebase
            this.setState({
              error: true,
              errorMessage: `Error fetching data: ${errorObject.message}`,
            });
          });
    }






    render() {
    return (
      <div className='p-4 flex flex-col items-center'>
            {/* <div> Shipment Listing</div>
            <div>
            {this.state.customerDate.map(dateItem=> {
                    const mdateObj =  new Date(Number(dateItem))


                    console.log(mdateObj,"DAytA")
                    const year = mdateObj.getFullYear();
                    const month = mdateObj.getMonth();
                    const date = mdateObj.getDate();
                    const item = `${year}-${month}-${date}`
                    
                    console.log(date,"DAA")
                return(
                    <li  className="customer-item p-2 border-b" onClick={()=>this.fetchData(dateItem)}>
                        {item}

                       
                    </li>

                )
            }
            )}

            </div> */}

              
    {this.state.freights.length ?
            <table id="default-table" className="table-auto">
                      <thead>
                          <tr>
                              <th className="p-2">
                                  <span className="flex items-center">
                                    Date Dropped
                                      
                                  </span>
                              </th>
                              <th className="p-2"
                              data-type="date" data-format="YYYY/DD/MM">
                                  <span className="flex items-center">
                                      Type
                                      
                                  </span>
                              </th>
                              <th className="p-2">
                                  <span className="flex items-center">
                                  Parcel Weight 
                                  </span>
                              </th>
                              <th className="p-2">
                                  <span className="flex items-center">
                                      No of Items
                                     
                                  </span>
                              </th>
                              <th className="p-2">
                                  <span className="flex items-center">
                                      Payment Status
                                      
                                  </span>
                              </th>
                              <th className="p-2">
                                  <span className="flex items-center">
                                      Amount Paid
                                     
                                  </span>
                              </th>
                          </tr>
                      </thead>
                      <tbody>

                {this.state.freights.map((customer, index) => (
                    <tr key={index} className='cursor-pointer'>
                        {/* <td className="font-medium text-gray-900 whitespace-nowrap dark:text-white">Flowbite</td> */}
            <td className="font-medium  p-2 text-blue-400 whitespace-nowrap ">{this.formatDate(customer.createdAt)}</td>
                        <td className=" p-2">{customer.freightType}</td>
                       <td className=" p-2">{customer.freightWeight} KG</td>
                       <td className=" p-2">{customer?.freightItems.length}</td>
                       <td className=" p-2">{customer?.paymentStatus}</td>
                       <td className=" p-2">{customer?.totalCost}</td>
                    </tr>
                    
                  ))}
                </tbody>
            </table>
            :
            <div className='font-bold text-2xl'>
              No Shipments Yet
            </div>
    }
      </div>
    )
  }
}
